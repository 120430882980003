import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ArrowRight from '../static/images/ArrowRight.svg'
import ArrowLeft from '../static/images/ArrowLeft.svg'
import ServiceDetail from './ServiceDetail'
import RecoditLogo from '../static/images/RecoditLogo.png'
import QuestionLogo from '../static/images/QuestionLogo.png'

function ServiceSection() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    nextArrow: <img src={ArrowRight} />,
    prevArrow: <img src={ArrowLeft} />
  };

  return (
    <ServiceContainer>
      <StyledSlider {...settings}>

        <ServiceDetail explanation="<레코딧>은 꿈꾸는 과정을 기록하기 위해 만들어졌습니다. 기록하는 삶을 함께하세요."
                image={RecoditLogo} serviceName='recodit' />

        <ServiceDetail explanation='새롭게 출시될 재밌는 서비스를 기대해주세요.'
        image={QuestionLogo} serviceName='question' />
      </StyledSlider>
    </ServiceContainer>
  )
}

export default ServiceSection

const ServiceContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const StyledSlider = styled(Slider)`

  height: 75vh;
  width: 75vw;

  .slick-list {
    width: 100%;
    height: 100%;
    border-radius: 48px;
  }

  .slick-slide {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 25vh;

  }

  .slick-slide div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .slick-dots {
    bottom: 20px;
    margin-top: 200px;
  }

  .slick-track {
    width: 100%;
    height: 100%;
  }

`;