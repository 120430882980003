import React from 'react'
import { styled } from 'styled-components'
// import Fun from '../static/images/Fun.gif'
import Fun from '../static/video/Fun.mp4'

function SloganDetailSection() {
  return (
    <SloganDetailContainer>
      <SloganDetailLeftContainer>
        <div className='title'>
            즐거운 것이라면
        </div>
        <div className='body'>
          다양한 서비스를 직접 기획·디자인·제작하는 개발사입니다. 
          장르에 제한을 두지 않고 즐거운 것이라면 모두 만듭니다.
        </div>
      </SloganDetailLeftContainer>

      <SloganDetailRightContainer>
        <video muted autoPlay loop src={Fun} />
      </SloganDetailRightContainer>
      
      
    </SloganDetailContainer>
  )
}

export default SloganDetailSection

const SloganDetailContainer = styled.div`
  display: flex;
  height: 100vh;
  background-color: black;
  align-items: center;
  justify-content: center;
  
  // for mobile
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const SloganDetailLeftContainer = styled.div`
  flex: 0.5;
  margin: 0 2rem 0 4rem;
  display: flex;
  flex-direction: column;
  color: #EFEFEF;

  @media screen and (max-width: 767px) {
    flex-grow: 0;
    margin: 4rem;
  }

  > .title {
    flex: 1;
    font-size: 4rem;
    font-weight: 600;
    padding-bottom: 1rem;
  }
  
  > .body {
    flex: 1;
    font-size: 2rem;
    line-height: 100%;
  }
`;

const SloganDetailRightContainer = styled.div`
  flex: 0.5;
  display: flex;

  > video {
    flex: 1;
    width: 20rem;
    height: 20rem;
  }
`;