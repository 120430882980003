import styled from 'styled-components'

function ServiceDetail({ image, explanation }) {
  return (
    <ServiceDetailContainer>
      <LogoImage src={image} />
      
      <ExplanationSection>
        {explanation}
      </ExplanationSection>
    </ServiceDetailContainer>
  )
}
 
export default ServiceDetail

const ServiceDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 6rem;
`;

const LogoImage = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 1rem;
  margin: 0 0 2rem 0;

  transform-style: preserve-3d;
  box-shadow:
    0.5rem 0.5rem 0.5rem 0 rgba(34, 33, 81, 0.1);

  transition:
    .4s ease-in-out transform,
    .4s ease-in-out box-shadow;

  &:hover {
    box-shadow:
      0.5rem 0.5rem 0.5rem 0 rgba(34, 33, 81, 0.01),
      0.75rem 0.75rem 0.75rem 0 rgba(34, 33, 81, 0.25);
    transform:
      translate3d(0px, -1rem, 0px);
  }
`;

const ExplanationSection = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  
  animation: wobble 1s 1;

  @keyframes wobble {
    25% {
      transform: rotate(2deg);
    }
    50% {
      transform: rotate(-4deg);
    }
    75% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
