import React from 'react'
import styled from 'styled-components'

function ContactSection() {
  return (
    <ContactContainer>
      <ContactTitle>
        연락하기
      </ContactTitle>
      <ContactBody>
        <div>
         너덴스 및 서비스에 대한 문의/제안은 아래 이메일로 보내주세요.
        </div>
        <div>
          {"myeongseok.seong@nerdens.com"}
        </div>
      </ContactBody>
    </ContactContainer>
  )
}

export default ContactSection

const ContactContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: #EFEFEF;

`;

const ContactTitle = styled.div`
  flex-grow: 0;
  font-size: 4rem;
  padding-bottom: 1.5rem;
`;

const ContactBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 2rem;

  > div {
    font-size: 1.5rem;
    line-height: 100%;
  }
`;