import React from 'react'
import { styled } from 'styled-components'

function SloganSection() {
  return (
    <SloganContainer>
      <Slogan>
        상상하고 만들고 :)
      </Slogan>
    </SloganContainer>
  )
}

export default SloganSection

const SloganContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const Slogan = styled.div`
  // font
  flex: 1;
  margin: 4rem;
  font-weight: 600;
  font-size: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
 
`;
