import React from 'react'
import styled from 'styled-components'

function Header() {
  return (
    <HeaderContainer>
      <Title>너덴스</Title>
    </HeaderContainer>
  )
}

export default Header;

const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  padding: 1rem 4rem;
  justify-content: center;
  background-color: black;
  text-align: left;
  z-index: 100;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 2rem;
  color: white
`;
