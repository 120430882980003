import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MainContent from './components/MainContent';

function App() {
  return (
    <>
      <MainContent />
    </>
    
  )
}

export default App;