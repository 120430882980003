import React from 'react'
import styled from 'styled-components'
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage'

import SloganSection from './Slogan'
import SloganDetailSection from './SloganDetail'
import Header from './Header'
import ServiceSection from './Service'
import ContactSection from './Contact'

function MainContent() {
  return (
    <MainContentContainer>
      <Fullpage>
        <FullPageSections>
          <FullPageContainer>
            <>
              <Header />
              <SloganSection />
            </>
          </FullPageContainer>
          <FullPageContainer>
            <SloganDetailSection />
          </FullPageContainer>
          <FullPageContainer>
            <ServiceSection />
          </FullPageContainer>
          <FullPageContainer>
            <ContactSection />
          </FullPageContainer>
        </FullPageSections>
      </Fullpage>
    </MainContentContainer>
  )
}


export default MainContent

const MainContentContainer = styled.div`
  display: flex;
`;

const FullPageContainer = styled(FullpageSection)`
  height: 100vh;
  /* padding-bottom: 10vh; */
`;